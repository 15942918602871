import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

const SolutionsPage = () => {
  return (
    <Layout>
      <section id="solutions"></section>
      <div className="top_section">
        <div className="bg sols"></div>
        <div className="inner_sub">
          <div className="top_text">Solutions</div>
          <p>
            Industry-leading 3D data platforms integrated with A-Z processes in
            managing construction sites, operational assets industry, smart city
            data platform and immersive 3D visualization : <br />
            C-Eagle, R-Eagle, T-Eagle, H-Eagle.
          </p>
          <div className="youtube-logo">
            <a href="https://www.youtube.com/channel/UCn_ZeOcxdBKqA53uePcM3kQ">
              <StaticImage
                style={{
                  maxWidth: "70px",
                }}
                src="../images/유튜브_채널_링크_이미지.png"
                alt="Youtube_channel"
              />
            </a>
            <StaticImage
              style={{
                maxWidth: "80px",
              }}
              src="../images/FindUs.png"
              alt="find_us"
            />
          </div>
          <a href="https://www.youtube.com/channel/UCn_ZeOcxdBKqA53uePcM3kQ?sub_confirmation=1">
            <div className="youtube-subscribe-logo">
              <StaticImage
                style={{
                  maxWidth: "80px",
                }}
                src="../images/유튜브_구독_링크_이미지.png"
                alt="Youtube_subscribe"
              />
            </div>
          </a>
        </div>
      </div>
      <div className="content">
        <section id="solutions"></section>
        {/* <div className="video_container"> */}
        <div className="video_container-conworth">
          <object
            type="text/html"
            width="100%"
            height="auto"
            data="https://www.youtube.com/embed/DoDBQ4j-aao"
            allowFullScreen
          ></object>
          {/* </div> */}
        </div>
        <div className="sub_section">
          <ul className="sol_list">
            <li>
              <div className="img">
                {/* <StaticImage src="../images/Solution_C-Eagle_1.png" alt="" /> */}
                <object
                  type="text/html"
                  width="100%"
                  height="100%"
                  data="https://www.youtube.com/embed/CsUpfwj_Nt0"
                  allowFullScreen
                ></object>
              </div>
              <div className="info">
                <strong className="tit">C-Eagle</strong>
                <p>
                  A 3D data integration platform for site management that
                  enables integrated management of diverse location-based data.
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                <object
                  type="text/html"
                  width="100%"
                  height="100%"
                  data="https://www.youtube.com/embed/rElI8uHo11g"
                  allowFullScreen
                ></object>
              </div>
              <div className="info">
                <strong className="tit">R-Eagle</strong>
                <p>
                  A 3D Asset management solution using autonomous robot
                  integrated with various IoT sensors that enables gas leak
                  detection, gauge reading, hazard monitoring.
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                {/* <StaticImage
                  // style={{
                  //   marginTop:"0px"
                  // }}
                  src="../images/Solution_T-Eagle_1.png"
                  alt=""
                /> */}
                <object
                  type="text/html"
                  width="100%"
                  height="100%"
                  data="https://www.youtube.com/embed/G8yVjrj0Ndc"
                  allowFullScreen
                ></object>
              </div>
              <div className="info">
                <strong className="tit">T-Eagle</strong>
                <p>
                  A 3D visualization solution for smart city with spatial big
                  data management and analysis using distributed server and
                  graphic technology.
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                {/* <StaticImage src="../images/Solution_H-Eagle_1.png" alt="" /> */}
                <object
                  type="text/html"
                  width="100%"
                  height="100%"
                  data="https://www.youtube.com/embed/BDpK9j4GBd4"
                  allowFullScreen
                ></object>
              </div>
              <div className="info">
                <strong className="tit">H-Eagle</strong>
                <p>
                  A 3D Visualization solution for immersive experiences through
                  mesh/texture optimization and web-based server-side rendering.
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                {/* <StaticImage src="../images/Conworth_3D_Note.png" alt="" /> */}
                <StaticImage src="../images/Conworth_3D_Note.png" alt="" />
              </div>
              <div className="info">
                <strong className="tit">Conworth 3D Note</strong>
                <p>
                  A 3D collaboration tool that enables user to create notes in a
                  visualized 3D space. With this tool, user can note and share
                  work details, memos, photos, and drawings related to issues.
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                {/* <StaticImage src="../images/Solution_H-Eagle_1.png" alt="" /> */}
                <object
                  type="text/html"
                  width="100%"
                  height="100%"
                  data="https://www.youtube.com/embed/kIpw2k6jsJQ"
                  allowFullScreen
                ></object>
              </div>
              <div className="info">
                <strong className="tit">Revit2Gltf Converter</strong>
                <p>
                  The Revit2Gltf Converter operates as an Add-in for Revit®,
                  enabling the direct conversion of Revit®'s element category
                  hierarchy into the node hierarchy of glTF. <br />
                  <a>(</a>
                  <a
                    style={{ color: "blue", textDecoration: "underline" }}
                    href="https://apps.autodesk.com/RVT/ko/Detail/Index?id=5014398626653845121&appLang=en&os=Win32_64&mode=preview"
                    target="_blank"
                    class="hyperlink"
                  >
                    Go to Autodesk App Store
                  </a>
                  <a>)</a>
                </p>
              </div>
            </li>
            <li>
              <div className="img">
                <object
                  type="text/html"
                  width="100%"
                  height="100%"
                  data="https://www.youtube.com/embed/lq_JVRTj8Ag"
                  allowFullScreen
                ></object>
              </div>
              <div className="info">
                <strong className="tit">
                  Conworth Gltf Converter (for Navisworks)
                </strong>
                <p>
                  The Conworth Gltf Converter operates as an Add-in for
                  Navisworks, enabling the direct conversion of Navisworks's
                  element category hierarchy into the node hierarchy of glTF.
                  <br />
                  (Please contact via email.)
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default SolutionsPage;
